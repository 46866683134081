import * as React from 'react';
import PropTypes from "prop-types";
import { isEmpty, keys } from "lodash";
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import {useUIDispatch} from "../../app/uiContext";
import {useAuth} from "../../context/authContext";
import {PrimaryActionButton} from "../../common/PrimaryActionButton";
import {useAddToCart} from "./hooks";
import {useMarkets} from "../../hooks/useCommerceLayer";

export const AddToCartButton = ({ buttonClasses, children, className, disabled = false, muiButtonParams = {}, product, tooltip = `Add to cart`, variant = `icon` }) => {
    const {isLoggedIn} = useAuth();
    const uiDispatch = useUIDispatch();
    const mutation = useAddToCart({product});
    const { marketIsLoading, data:market } = useMarkets({filters: {Api: {referenceEq: product.sellerId}}});
    const marketId = market && market.data && market.data[0].id;

    const handleAddToCart = async () => {
        if (isLoggedIn()) {
            try {
                mutation.mutate({ marketId });
                uiDispatch({type:`SHOW_CART`});
            } catch (error) {
                console.log(error)
            }
        } else {
            uiDispatch({type:`SHOW_LOGIN`});
        }
    }

    const addToCartDisabled = isEmpty(keys(product)) ? true : disabled || marketIsLoading || mutation.isLoading;

    return <PrimaryActionButton
        buttonClasses={buttonClasses}
        className={className}
        disabled={addToCartDisabled}
        handleClick={handleAddToCart}
        icon={<AddShoppingCartIcon />}
        muiButtonParams={muiButtonParams}
        tooltip={tooltip}
        variant={variant}
    >
        {children}
    </PrimaryActionButton>
};

AddToCartButton.propTypes = {
    buttonClasses: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    muiButtonParams: PropTypes.object,
    product: PropTypes.object.isRequired,
    tooltip: PropTypes.string,
    variant: PropTypes.oneOf([`icon`,`iconText`,`text`]),
}
