import * as React from 'react';
import PropTypes from "prop-types";
import {Link} from "gatsby";
import {Card, CardContent, ImageListItemBar, Typography} from "@material-ui/core";
import {Skeleton} from "@material-ui/lab";
import {makeStyles} from "@material-ui/core/styles";
import {CardImage} from "../cards/CardImage";
import {cardCondition} from "../../utils/card-condition";
import {CardRarity} from "../cards/CardRarity";
import {UserWidget} from "../users/UserWidget";
import {ProposeTradeButton} from "../trades/ProposeTradeButton";
import {useCard} from "../cards/hooks";
import {AddToCartButton} from "../cart/AddToCartButton";
import {Price} from "./Price";
import {FlagIcon} from "../../components/FlagIcon";
import {getCardImageUrl} from "../../utils/get-card-image-url";

const useStyles = makeStyles(theme => ({
    productCard: {
        [`@media (min-width: 640px)`]: {
            width: `30rem`,
        },
    },
}));

const cardStyle = (styleRef) => {
    switch (styleRef) {
        case `F`:
            return `Foiled`
        case `R`:
            return `Reverse foiled`
        case `S`:
            return `Standard`
        default:
            return `Unknown`
    }
}

export const ProductItemHorizontal = ({ hideUser = false, item, paperClasses = `` }) => {
    const {cardIsLoading, data:card} = useCard(item.cardId);
    const classes = useStyles();
    const selling = !!item.skuCode && item.ownerId > 0;

    if (cardIsLoading || !card) { return <Skeleton animation="pulse" height={250} variant="rect" /> }

    const cardAssetUrl = card.data.image && card.data.image.data.asset_url;
    const product = {
        imageUrl: getCardImageUrl({ assetUrl:cardAssetUrl, fullUrl:card.data.image_url }),
        quantityAvailable: item.quantity,
        name: card.data.name,
        sellerId: item.ownerId,
        skuCode: item.skuCode,
    }

    return (
        <li className="m-4 sm:flex-none w-full sm:w-auto">
            <Card className={`flex flex-col h-full pr-12 relative sm:flex-row ${classes.productCard} ${paperClasses}`} elevation={1} sku={item.skuCode}>
                <div className={`flex-shrink-0 relative`}>
                    <Link className="hover:opacity-75" to={`/card/${card.data.id}/${card.data.url_title}/`}>
                        <CardImage alt={card.data.name} assetUrl={cardAssetUrl} className="" fullUrl={card.data.image_url} height={250} width={179} />
                    </Link>
                    <ImageListItemBar
                        subtitle={<CardRarity rarityNum={(card.data.rarity && card.data.rarity.id) || 0} />}
                        title={card.data.reference ? `Ref: ${card.data.reference}` : card.data.name}
                    />
                </div>
                <CardContent className="flex flex-col flex-initial overflow-hidden">
                    <Typography className="truncate" component="h2" variant="h5">
                        <Link className="hover:opacity-75" to={`/card/${card.data.id}/${card.data.url_title}/`}>{card.data.name} {card.data.subtitle ? (<>({card.data.subtitle})</>) : null}</Link>
                    </Typography>
                    <ul className="mb-2 opacity-75 text-white">
                        <li className="truncate">Set: {card.data.set.name}</li>
                    </ul>

                    {selling && <Price className="mb-2 text-xl" skuCode={item.skuCode} userId={item.ownerId} />}
                    <ul className="mb-2 opacity-75 text-white">
                        <li>Available: {item.quantity}</li>
                        <li>Condition: {cardCondition[item.conditionId]}</li>
                        <li>Language: <FlagIcon code={item.language}/></li>
                        <li>Signed: {item.signed ? `Yes` : `No`}</li>
                        <li>Style: {cardStyle(item.style)}</li>
                    </ul>
                    {!hideUser && <UserWidget userId={item.ownerId} variant="small" />}
                </CardContent>
                <div className="absolute m-2 right-0 top-0">
                    {selling ?
                        <AddToCartButton product={product} />
                    :
                        <ProposeTradeButton userId={item.ownerId} />
                    }
                </div>
            </Card>
        </li>
    );
};

ProductItemHorizontal.propTypes = {
    hideUser: PropTypes.bool,
    item: PropTypes.shape({
        ownerId: PropTypes.number.isRequired,
        cardId: PropTypes.number.isRequired,
        quantity: PropTypes.number.isRequired,
        language: PropTypes.string.isRequired,
        conditionId: PropTypes.number.isRequired,
        style: PropTypes.oneOf([`S`,`F`,`R`]).isRequired,
        signed: PropTypes.bool.isRequired,
        skuCode: PropTypes.string,
    }).isRequired,
    paperClasses: PropTypes.string
};
