import * as React from "react";
import PropTypes from "prop-types";
import {NumberParam, StringParam, useQueryParams} from "use-query-params";
import {Typography} from "@material-ui/core";
import {Pagination, Skeleton} from "@material-ui/lab";
import {ProductItemHorizontal} from "./ProductItemHorizontal";
import {useStockItems} from "../../hooks/useCommerceLayer";
import {splitSku} from "./utils";

/*
    filterConstants allows you to override/force a url query param. For example the userId on a user profile page.
 */
export const ProductResults = ({filterConstants}) => {
    const [query, setQuery] = useQueryParams({
        g: StringParam,
        l: StringParam,
        page: NumberParam,
        q: StringParam,
        s: NumberParam,
        se: NumberParam,
        u: NumberParam,
    });
    const handlePageChange = (event, value) => {
        if (value !== query.page) {
            setQuery({page: value});
        }
    };
    const {isLoading, data:stock} = useStockItems({ filters:{...query, ...filterConstants }});
    if (isLoading) { return <Skeleton animation="pulse" height={500} variant="rect" /> }

    return stock ? (
        <>
            <Typography className="pb-4">Total results: {stock.meta.filter_count}</Typography>
            <ul className="flex flex-wrap -m-4">
                {stock.data.map(item => {
                    const sku = splitSku(item.attributes.sku_code);
                    return <ProductItemHorizontal
                        hideUser={filterConstants && !!filterConstants.u}
                        item={{
                            ownerId: Number(item.attributes.reference),
                            cardId: sku.cardId,
                            quantity: item.attributes.quantity,
                            language: sku.language,
                            conditionId: sku.conditionId,
                            style: sku.style,
                            signed: sku.signed,
                            skuCode: item.attributes.sku_code,
                        }}
                        key={item.id}/>
                })}
            </ul>
            <Pagination className="mt-4" count={stock.meta.page_count} onChange={handlePageChange} page={query.page} />
        </>
    ) : null;
}

ProductResults.propTypes = {
    filterConstants: PropTypes.object,
}
