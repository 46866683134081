import * as React from 'react';
import {Collapse, Grid, ListItem, ListItemText, Paper} from "@material-ui/core";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import {useIsMounted} from "../common/hooks";
import {Layout} from "../common/Layout";
import {SEO} from "../components/seo";
import {Heading} from "../components/heading";
import {CardFilterForm} from "../features/cards/CardFilterForm";
import {ProductResults} from "../features/products/ProductResults";

const MarketplaceScreen = () => {
    const isMounted = useIsMounted();
    const [filtersOpen, setFiltersOpen] = React.useState(false);
    const toggleFiltersOpen = () => {setFiltersOpen(!filtersOpen)}

    return (
        <Layout>
            <SEO title="Marketplace" />
            <Grid className="mb-4" container>
                <Grid item xs={12}>
                    <Heading className="mb-4" text="Marketplace" />
                </Grid>
                <Grid item lg={4} md={6} xs={12}>
                    <Paper>
                        <ListItem button onClick={toggleFiltersOpen}>
                            <ListItemText primary={`Filters`} />
                            {filtersOpen ? <ExpandMore /> : <ExpandLess />}
                        </ListItem>
                        <Collapse className="p-4" in={filtersOpen} timeout="auto" unmountOnExit>
                            <CardFilterForm />
                        </Collapse>
                    </Paper>
                </Grid>
            </Grid>
            {isMounted ? (
                <ProductResults />
            ) : null}
        </Layout>
    )
};

export default MarketplaceScreen;
